import { useEffect, useState } from 'react'
import * as yup from 'yup'

import BioTab from 'domains/Profile/BioTab'
import GeneralTab from 'domains/Profile/GeneralTab'
import ProductTab from 'domains/Profile/ProductTab'
import WhyImHereTab from 'domains/Profile/WhyImHereTab'
import useTimezoneDetection from 'domains/Profile/hooks/useTimezoneDetection'
import { textLengthsAreValid } from 'domains/Profile/utils'

import { ErrorMessage } from 'components'
import Button from 'components/Button'
import { useGlobalModal } from 'components/GlobalModal'
import Loading from 'components/Loading'
import { Modal, ModalContent, useModal } from 'components/Modal'
import Tabs from 'components/Tabs'
import { CheckmarkBorderlessIcon } from 'components/icons'

import {
  ABOUT_MY_PRODUCT_TAB,
  ABOUT_MY_PRODUCT_TAB_COPY,
  BIO_AND_EXPERIENCE_TAB,
  BIO_AND_EXPERIENCE_TAB_COPY,
  GENERAL_TAB,
  GENERAL_TAB_COPY,
  WHAT_IM_SEEKING_TAB,
  WHAT_IM_SEEKING_TAB_COPY
} from 'constants/profile'

import {
  OnboardingQuestionPartsFragment,
  PreviousCompaniesFieldsFragment,
  ProfileEditModalQueryHookResult,
  ProfileEditModalUserFieldsFragment,
  ProfileEditModalUserProfileFieldsFragment,
  useProfileEditModalQuery,
  useSubmitOnboardingQuestionnaireMutation,
  useTimezonesQuery,
  useUpdateUserMutation,
  useUqQuestionnareQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  trackAccountUpdated as segmentTrackAccountUpdated,
  trackModalDisplayed,
  trackMyAccountProfileUpdated,
  trackNavigationClicked
} from 'utils/tracking/analytics'

interface TrackAccountUpdatedOptions {
  user: ProfileEditModalUserFieldsFragment
  profile: ProfileEditModalUserProfileFieldsFragment
  previousCompanies: PreviousCompaniesFieldsFragment[]
  avatarUrl: string | null | undefined
}

const trackAccountUpdated = ({
  user,
  profile,
  previousCompanies,
  avatarUrl
}: TrackAccountUpdatedOptions) => {
  segmentTrackAccountUpdated({
    access_policy_kind: user.accessPolicyKind,
    business_model: profile.businessModelTags?.split(','),
    cohort: user.cohorts?.current.map(
      ({ season, programName }) => `${season} ${programName}`
    ),
    company: profile.companyName || undefined,
    connecting_goal: profile.communityInterestTags?.split(','),
    experience: profile.areasOfExpertiseTags?.split(','),
    first_name: profile.firstName || undefined,
    former_companies: previousCompanies
      .map((pc) => pc.company.name)
      .filter((name) => !!name) as string[],
    image_url: avatarUrl || undefined,
    last_name: profile.lastName || undefined,
    linkedin_url: profile.linkedin || undefined,
    location: profile.location,
    product_audience: profile.companyAudienceTags?.split(','),
    timezone: profile.timezone,
    twitter_url: profile.twitter,
    user_job_title: profile.role || undefined
  })
}

interface ProfileEditModalContainerProps {
  autofocusField?: string
  initialTab: number
  showTabs?: boolean
}

export default function ProfileEditModalContainer({
  autofocusField,
  initialTab,
  showTabs
}: ProfileEditModalContainerProps) {
  const {
    loading: profileLoading,
    data: profileData,
    refetch: refetchProfile
  } = useProfileEditModalQuery()
  const { data: timezonesData } = useTimezonesQuery()
  const { data: uqQuestionnaireData } = useUqQuestionnareQuery({
    variables: {
      questionnaireId: '2'
    }
  })

  if (profileLoading) {
    return (
      <div className="mt-5">
        <Loading />
      </div>
    )
  }

  if (!profileData?.currentUser) {
    return <ErrorMessage error={new Error('Failed to load user.')} />
  }

  return (
    <ProfileEditModal
      autofocusField={autofocusField}
      initialTab={initialTab}
      questionnaireQuestions={uqQuestionnaireData?.uqQuestionnaire?.questions || []}
      refetchProfile={refetchProfile}
      showTabs={showTabs}
      timezones={timezonesData?.timezones || []}
      user={profileData?.currentUser}
    />
  )
}

const profileSchemaUserIsNotCreator = yup.object().shape({
  firstName: yup.string().required('This field is required.').nullable(),
  lastName: yup.string().required('This field is required.').nullable(),
  companyName: yup.string().required('This field is required.').nullable(),
  role: yup.string().required('This field is required.').nullable()
})

const profileSchemaUserIsCreator = profileSchemaUserIsNotCreator.shape({
  aboutMeText: yup.string().required('This field is required.').nullable(),
  ctaUrl: yup
    .string()
    .matches(/^((http|https|mailto):.*)?$/, 'Please enter a valid URL or mailto link.')
    .nullable()
})

function profileFormIsValid(
  currentUser: ReturnType<typeof useCurrentUser>['currentUser'],
  profile: ProfileEditModalUserProfileFieldsFragment
): {
  isValid: boolean
  errors: { [key: string]: string }
} {
  if (!currentUser) return { isValid: false, errors: {} }

  const profileSchema = currentUser.is.creator
    ? profileSchemaUserIsCreator
    : profileSchemaUserIsNotCreator

  let isSchemaValid = false
  let errors = {}

  try {
    isSchemaValid = profileSchema.isValidSync(profile, { abortEarly: false })
    if (!isSchemaValid) {
      profileSchema.validateSync(profile, { abortEarly: false })
    }
  } catch (err) {
    // ValidationErrors -> { [path]: errorMessages[0] }
    errors = err.inner.reduce(
      (
        acc: Record<string, string>,
        currVal: { path: string; errors: Array<string> }
      ) => ({
        ...acc,
        [currVal.path]: currVal.errors[0] ?? ''
      }),
      {}
    )
  }

  const isValid = isSchemaValid && textLengthsAreValid(profile)

  return { isValid, errors }
}

interface ProfileEditModalProps extends ProfileEditModalContainerProps {
  autofocusField?: string
  initialTab: number
  questionnaireQuestions: OnboardingQuestionPartsFragment[]
  refetchProfile: ProfileEditModalQueryHookResult['refetch']
  showTabs?: boolean
  timezones: string[]
  user: ProfileEditModalUserFieldsFragment
}

function ProfileEditModal({
  autofocusField,
  initialTab,
  questionnaireQuestions,
  refetchProfile,
  showTabs = false,
  timezones,
  user
}: ProfileEditModalProps) {
  const { currentUser } = useCurrentUser()

  const [updateUser, { loading: userLoading }] = useUpdateUserMutation()
  const [submitOnboardingQuestionnaire, { loading: questionnaireLoading }] =
    useSubmitOnboardingQuestionnaireMutation()

  const [activeTab, setActiveTab] = useState(initialTab)
  const [canSave, setCanSave] = useState(true)
  const [avatarUrl, setAvatarUrl] = useState<string>()
  const [profile, setProfile] =
    useState<ProfileEditModalUserProfileFieldsFragment | null>(null)
  const [isUpdatingAvatar, setIsUpdatingAvatar] = useState(false)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})

  const isSaving = userLoading || questionnaireLoading

  const {
    isModalOpen: isConfirmationModalOpen,
    setIsModalOpen: setIsConfirmationModalOpen
  } = useModal()
  const { closeGlobalModal } = useGlobalModal()

  // previous companies are updated right away so we use apollo state directly
  const previousCompanies = user?.profile.previousCompanies || []

  useEffect(() => {
    setActiveTab(initialTab)
  }, [initialTab])

  const onTimezoneUpdate = (detectedTimezone: string) => {
    setProfile(
      (existingProfile) =>
        existingProfile && {
          ...existingProfile,
          timezone: detectedTimezone
        }
    )
  }

  useTimezoneDetection(onTimezoneUpdate)

  const resetData = () => {
    setProfile({
      ...user.profile
    })
    setAvatarUrl(user.profile.avatarUrl)
  }

  useEffect(() => {
    resetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id])

  useEffect(() => {
    if (profile) {
      const { isValid, errors } = profileFormIsValid(currentUser, profile)
      setCanSave(isValid)
      setFormErrors(errors)
    }
  }, [currentUser, profile])

  const hideSelf = () => {
    closeGlobalModal()
  }

  const onNewAvatar = (dataUrl: string) => {
    setAvatarUrl(dataUrl)
    setIsUpdatingAvatar(false)
    refetchProfile()
  }

  const handleCancel = () => {
    hideSelf()
    document.getElementById('page')?.scrollTo(0, 0)
  }

  const handleSave = async () => {
    setCanSave(false)
    if (user && profile) {
      await submitOnboardingQuestionnaire({
        variables: {
          input: {
            areasOfExpertise: profile.areasOfExpertiseTags,
            businessModel: profile.businessModelTags,
            companyAudience: profile.companyAudienceTags
          }
        }
      })
      await updateUser({
        variables: {
          input: {
            aboutMeText: profile.aboutMeText,
            askMeAboutText: profile.askMeAboutText,
            communityInterestText: profile.communityInterestText,
            companyName: profile.companyName,
            companyProductText: profile.companyProductText,
            ctaText: profile.ctaText,
            ctaUrl: profile.ctaUrl,
            firstName: profile.firstName,
            lastName: profile.lastName,
            learningGoalsText: profile.learningGoalsText,
            linkedin: profile.linkedin,
            location: profile.location,
            role: profile.role,
            timezone: profile.timezone,
            twitter: profile.twitter,
            slackAllowDirectMessages: profile.allowDirectMessages,
            slackResponseTime: 1
          }
        }
      })

      trackAccountUpdated({
        user,
        profile,
        previousCompanies,
        avatarUrl
      })
      trackMyAccountProfileUpdated({
        access_policy_kind: user.accessPolicyKind,
        user_id: user.id,
        profile_fields: profile
      })
      hideSelf()
      document.getElementById('page')?.scrollTo(0, 0)
    }
  }

  return (
    <>
      {user && profile && (
        <div data-test="member-profile-edit-modal">
          <div className="flex min-h-[835px] flex-col p-0">
            <>
              <div className="flex-1 pl-2.5 pt-2.5 pr-2.5 xs:pl-4 xs:pt-4 xs:pr-4 md:pl-8 md:pt-8 md:pr-8">
                <div className="text-2xl font-semibold leading-7">Edit Profile</div>
                {!isUpdatingAvatar && (
                  <div className="mt-4 mb-4">
                    {showTabs && (
                      <Tabs
                        tabs={[
                          {
                            label: GENERAL_TAB_COPY,
                            onClick: () => {
                              trackProfileNavigationClick(GENERAL_TAB_COPY)
                              setActiveTab(GENERAL_TAB)
                            },
                            isActive: activeTab === GENERAL_TAB
                          },
                          {
                            label: BIO_AND_EXPERIENCE_TAB_COPY,
                            onClick: () => {
                              trackProfileNavigationClick(BIO_AND_EXPERIENCE_TAB_COPY)
                              setActiveTab(BIO_AND_EXPERIENCE_TAB)
                            },
                            isActive: activeTab === BIO_AND_EXPERIENCE_TAB
                          },
                          {
                            label: ABOUT_MY_PRODUCT_TAB_COPY,
                            onClick: () => {
                              trackProfileNavigationClick(ABOUT_MY_PRODUCT_TAB_COPY)
                              setActiveTab(ABOUT_MY_PRODUCT_TAB)
                            },
                            isActive: activeTab === ABOUT_MY_PRODUCT_TAB
                          },
                          {
                            label: WHAT_IM_SEEKING_TAB_COPY,
                            onClick: () => {
                              trackProfileNavigationClick(WHAT_IM_SEEKING_TAB_COPY)
                              setActiveTab(WHAT_IM_SEEKING_TAB)
                            },
                            isActive: activeTab === WHAT_IM_SEEKING_TAB
                          }
                        ]}
                        size="small"
                      />
                    )}
                  </div>
                )}
                <GeneralTab
                  activeTab={activeTab}
                  avatarUrl={avatarUrl}
                  isEditingImage={isUpdatingAvatar}
                  onNewAvatar={onNewAvatar}
                  previousCompanies={previousCompanies}
                  profile={profile}
                  timezones={timezones}
                  setIsUpdatingAvatar={setIsUpdatingAvatar}
                  setProfile={setProfile}
                  user={user}
                  formErrors={formErrors}
                />
                {!isUpdatingAvatar && activeTab === 2 && (
                  <BioTab
                    autofocusField={autofocusField}
                    profile={profile}
                    setProfile={setProfile}
                  />
                )}
                {!isUpdatingAvatar && activeTab === 3 && (
                  <ProductTab
                    profile={profile}
                    questions={questionnaireQuestions}
                    setProfile={setProfile}
                  />
                )}
                {!isUpdatingAvatar && activeTab === 4 && (
                  <WhyImHereTab
                    autofocusField={autofocusField}
                    questions={questionnaireQuestions}
                    profile={profile}
                    setProfile={setProfile}
                  />
                )}
              </div>
              {!isUpdatingAvatar && (
                <div className="mt-6 bg-rb-gray-50 px-2 sm:px-4 md:px-8">
                  <div className="flex flex-col items-center pt-6 sm:ml-6 sm:flex-row-reverse sm:justify-start sm:pb-6">
                    <Button
                      size="small"
                      type="submit"
                      shape="rounded-none"
                      onClick={handleSave}
                      disabled={!canSave}
                      isLoadingSpinner={isSaving}
                      dataTest="member-profile-edit-save-changes"
                    >
                      Save Changes
                    </Button>
                    <Button variant="text-only" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      )}
      <Modal
        isOpen={isConfirmationModalOpen}
        handleClose={() => {
          setIsConfirmationModalOpen(false)
        }}
        autoCloseInSeconds={3}
        header={false}
        className="h-48 max-w-[825px] sm:mb-[650px] sm:h-[239px]"
      >
        <ModalContent>
          <div className="m-auto">
            <div className="flex justify-center pt-8 sm:pt-14">
              <h3 className="text-3xl font-semibold">
                <span className="text-5xl">
                  <CheckmarkBorderlessIcon></CheckmarkBorderlessIcon>
                </span>
              </h3>
            </div>
            <div className="text-center">
              <p className="text-lg text-slate-500">Your profile has been updated!</p>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}

function trackProfileNavigationClick(text: string) {
  trackNavigationClicked({
    location: 'edit_profile_modal',
    type: 'hyperlink text',
    text
  })
}

export function trackProfileEditModalDisplayed() {
  trackModalDisplayed({
    category: 'app',
    modal_group: 'edit profile',
    modal_name: 'edit profile'
  })
}

export function useProfileEditModal() {
  const { openGlobalModal } = useGlobalModal()

  const openProfileEditModal = (
    props: ProfileEditModalContainerProps = { initialTab: GENERAL_TAB }
  ) => {
    openGlobalModal(<ProfileEditModalContainer showTabs {...props} />, {
      className: 'lg:w-[970px]',
      header: false,
      scrollContent: false
    })
    trackProfileEditModalDisplayed()
  }

  return { openProfileEditModal }
}
