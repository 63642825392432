import { useEffect, useState } from 'react'

import { INDUSTRY_OPTIONS } from 'domains/Ai/Chat/helpers'

import { ErrorMessage } from 'components'
import Button from 'components/Button'
import { Pill } from 'components/Pill'

import {
  AiPersonalization,
  AiPersonalizationDocument,
  ArtifactTopicFilter,
  CurrentUserDocument,
  RecommendedContentDocument,
  RecommendedCoursesDocument,
  useTrackOnboardingMutation,
  useUpdateAiPersonalizationMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { capitalizeFirstLetter } from 'utils/stringUtils'
import { cn } from 'utils/tailwind'
import { displayErrorToast, displaySuccessToast } from 'utils/toastService'
import { trackCtaClicked } from 'utils/tracking/analytics'

interface PersonalizeContentProps {
  onSave?: () => void
  onCancel?: () => void
  dedupedTopicsAndFunctions: ArtifactTopicFilter[]
  formIsDirty?: boolean
  setFormIsDirty?: (formIsDirty: boolean) => void
  personalizationData?: AiPersonalization | null
  isModal?: boolean
}

const MIN_SELECTED_VALUES = 2

export const SELECT_AUDIENCE_PLACEHOLDER = "Select your product's audience"
export const SELECT_INDUSTRY_PLACEHOLDER = "Select your product's industry"

const PersonalizeContent = ({
  onSave,
  onCancel,
  dedupedTopicsAndFunctions,
  personalizationData,
  formIsDirty,
  setFormIsDirty,
  isModal = false
}: PersonalizeContentProps) => {
  const { currentUser } = useCurrentUser()
  const [trackOnboarding] = useTrackOnboardingMutation()
  const [selectedValues, setSelectedValues] = useState<ArtifactTopicFilter[]>([])
  const [saveDisabled, setSaveDisabled] = useState(formIsDirty)
  const [selectedTopicsChanged, setSelectedTopicsChanged] = useState(false)
  const [role, setRole] = useState('')
  const [company, setCompany] = useState('')
  const [audience, setAudience] = useState('')
  const [industry, setIndustry] = useState('')
  const [additionalDetails, setAdditionalDetails] = useState('')

  const [updateAiPersonalization, { loading: updating, error: updateError }] =
    useUpdateAiPersonalizationMutation({
      onCompleted: (result) => {
        if (result?.updateAiPersonalization?.errors) {
          displayErrorToast({
            message: `Failed to update personalization: ${result.updateAiPersonalization.errors.join(
              ', '
            )}`
          })
        } else {
          displaySuccessToast({
            message: 'Changes saved'
          })
          onSave?.()
        }
      },
      onError: (error) => {
        displayErrorToast({
          message: `Failed to update personalization: ${error.message}`
        })
      }
    })

  useEffect(() => {
    trackOnboarding({
      variables: {
        input: {
          onboardingType: 'artifacts',
          stepName: 'select_focus_areas',
          stepNumber: 1,
          startedEvent: true
        }
      }
    })
  }, [trackOnboarding])

  useEffect(() => {
    if (
      currentUser?.networkFocus &&
      currentUser.networkFocus.length > 0 &&
      dedupedTopicsAndFunctions
    ) {
      const selectedTopics = dedupedTopicsAndFunctions?.filter((tf) =>
        currentUser.networkFocus?.includes(tf.id)
      )

      setSelectedValues(selectedTopics)
    }
  }, [currentUser, dedupedTopicsAndFunctions])

  useEffect(() => {
    if (
      !updating &&
      selectedValues.length >= MIN_SELECTED_VALUES &&
      (role !== personalizationData?.role ||
        company !== personalizationData?.company ||
        audience !== personalizationData?.audience ||
        industry !== personalizationData?.industry ||
        additionalDetails !== personalizationData?.additionalDetails ||
        selectedTopicsChanged)
    ) {
      setSaveDisabled(false)
      setFormIsDirty?.(true)
    } else {
      setSaveDisabled(true)
      setFormIsDirty?.(false)
    }
  }, [
    selectedValues,
    personalizationData,
    company,
    audience,
    industry,
    additionalDetails,
    role,
    updating,
    selectedTopicsChanged,
    setFormIsDirty
  ])

  useEffect(() => {
    setRole(personalizationData?.role ?? '')
    setCompany(personalizationData?.company ?? '')
    setAudience(personalizationData?.audience ?? '')
    setIndustry(personalizationData?.industry ?? '')
    setAdditionalDetails(personalizationData?.additionalDetails ?? '')
  }, [personalizationData])

  const handleCheckboxChange = (data: ArtifactTopicFilter, checked: boolean) => {
    let temp = [...selectedValues]

    if (checked) {
      temp.push(data)
    } else {
      temp = temp.filter((el) => el.id !== data.id)
    }
    setSelectedTopicsChanged(true)
    setSelectedValues(temp)
  }

  const cancelText = 'Cancel'
  const saveText = 'Save'

  const handleCancel = async () => {
    trackCtaClicked({
      cta_location: 'artifact_onboarding__focus_areas',
      cta_type: 'button',
      text: cancelText.toLowerCase()
    })

    onCancel?.()
  }

  const handleSave = () => {
    trackCtaClicked({
      cta_location: 'artifact_onboarding__focus_areas',
      cta_type: 'button',
      text: saveText
    })

    trackOnboarding({
      variables: {
        input: {
          onboardingType: 'artifacts',
          stepName: 'select_focus_areas',
          stepNumber: 1,
          option1Name: 'selected topics',
          option1Value: selectedValues.map((val) => val.title).join(', ')
        }
      }
    })
    updateAiPersonalization({
      variables: {
        input: {
          enabled: true,
          role,
          company,
          audience,
          industry,
          additionalDetails,
          topicIds: selectedValues.map((val) => val.id)
        }
      },
      refetchQueries: () => [
        AiPersonalizationDocument,
        CurrentUserDocument,
        // It's possible we may want to prevent these last two from updating on every personalization update
        // We don't use several of the fields for these queries
        RecommendedContentDocument,
        RecommendedCoursesDocument
      ]
    })
    onSave?.()
  }

  if (updateError) {
    return <ErrorMessage error="An error occurred" />
  }

  return (
    <div
      className={cn(
        'px-0 md:px-0 @container h-full',
        isModal ? '' : 'hide-scrollbar overflow-y-auto'
      )}
    >
      <form className="@sm:px-4 @md:px-12 @sm:pb-4 @md:pb-0 h-full relative">
        <h2 className="text-xl font-semibold text-rb-black">
          Personalize your experience
        </h2>
        <div className="text-sm">
          This helps tailor recommendations and chat responses directly to you. Updating
          these fields will also update your Reforge member profile.
        </div>
        <div className="mt-4 space-y-4 hide-scrollbar overflow-y-auto">
          <div>
            <label
              htmlFor="additional-details"
              className="block text-sm font-medium text-gray-700"
            >
              Is there anything you&apos;d like us to know about you?
            </label>
            <textarea
              id="additional-details"
              name="additional-details"
              rows={4}
              value={additionalDetails}
              onChange={(e) => setAdditionalDetails(e.target.value)}
              className="h-40 mt-1 block w-full resize-none rounded-md border border-gray-300 px-4 py-3 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Tell us about yourself, including any goals, challenges, or specific needs you have. Feel free to share your bio or resume to better customize the responses to you."
            ></textarea>
          </div>
          <div>
            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <input
              type="text"
              id="role"
              name="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="mt-1 block h-12 w-full rounded-md border border-gray-300 px-4 py-3 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Add your role"
            />
          </div>
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-700">
              Company
            </label>
            <input
              type="text"
              id="company"
              name="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              className="mt-1 block h-12 w-full rounded-md border border-gray-300 px-4 py-3 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Add your company"
            />
          </div>
          <div className="relative inline-block w-full">
            <label htmlFor="audience" className="block text-sm font-medium text-gray-700">
              Audience
            </label>
            <select
              name="audience"
              id="audience"
              value={audience}
              onChange={(e) => setAudience(e.target.value)}
              className="block w-full appearance-none rounded border border-gray-300 bg-white py-3 px-4 pr-8 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
            >
              <option value="">{SELECT_AUDIENCE_PLACEHOLDER}</option>
              <option value="B2B">Business-to-Business (B2B)</option>
              <option value="B2C">Business-to-Consumer (B2C)</option>
              <option value="B2B,B2C">Both (B2B and B2C)</option>
            </select>
          </div>
          <div className="relative inline-block w-full">
            <label htmlFor="industry" className="block text-sm font-medium text-gray-700">
              Industry
            </label>
            <select
              name="industry"
              id="industry"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              className="block w-full appearance-none rounded border border-gray-300 bg-white py-3 px-4 pr-8 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
            >
              <option value="">{SELECT_INDUSTRY_PLACEHOLDER}</option>
              {INDUSTRY_OPTIONS.map((industryOption) => (
                <option key={industryOption} value={industryOption}>
                  {industryOption}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div className="font-semibold text-rb-black text-sm">
              What topics are you currently focused on? Select at least 2.
            </div>
            <div className="flex flex-initial flex-col">
              <div className="flex-start my-5 flex flex-wrap items-start self-stretch">
                {dedupedTopicsAndFunctions.map((tf) => {
                  const isChecked = !!selectedValues.find((item) => {
                    return item.id === tf.id
                  })

                  return (
                    <Pill
                      isActive={isChecked}
                      key={tf.id}
                      id={tf.id}
                      onClick={() => handleCheckboxChange(tf, !isChecked)}
                      activeClassName="bg-rb-gray text-rb-gray-400 hover:text-rb-gray-400 border-rb-gray"
                      className="mb-4 mr-4 text-sm text-rb-gray-400 hover:bg-rb-gray hover:text-rb-gray-400  hover:border-rb-gray"
                      size="x-small"
                      dataTest="focus-areas-pill"
                    >
                      {capitalizeFirstLetter(tf.title)}
                    </Pill>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between flex-col sticky bottom-0 left-0 right-0 bg-white">
          <div className="flex justify-center @sm:justify-end space-x-4 mt-2 @sm:pb-8 @sm:pt-4">
            <Button
              type="button"
              variant="outline"
              color="default"
              className="offset-2 rounded-md text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={saveDisabled}
              className="ml-4"
              onClick={handleSave}
              color="teal"
              dataTest="focus-areas-save-cta"
            >
              {saveText}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PersonalizeContent
