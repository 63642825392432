import React, { Fragment, useLayoutEffect } from 'react'
import ScrollToBottom, { useScrollToBottom } from 'react-scroll-to-bottom'

import { Loading } from 'components'

import { cn } from 'utils/tailwind'

import reforgeSymbol from 'images/reforge-logo-symbol.svg'

import { ModeName } from '../GlobalChatProvider'
import { GlobalChatUIProps, OnGeneratedDraftCtaClickProps } from '../GlobalChatUI'
import { InChatSuggestedPrompts } from '../SuggestedPrompts'
import { CopyableProvider } from '../hooks/useCopyable'
import { Feedback } from './Feedback'
import { MessageCard } from './MessageCard'
import ScrollToBottomButton from './ScrollToBottomButton'
import { ASSISTANT_MESSAGE_BUBBLE_CLASSES } from './helpers'
import { useDelayMessageTimer } from './useDelayMessageTimer'

export type ChatFeedProps = Pick<
  GlobalChatUIProps,
  | 'chatId'
  | 'messages'
  | 'isLoading'
  | 'loadingSession'
  | 'reload'
  | 'mode'
  | 'suggestedPrompts'
  | 'suggestedPromptsLoading'
  | 'sendMessage'
> & {
  onSourceLinkClick: () => void
  draftTemplateName?: string
  onGeneratedDraftCtaClick: ({
    message,
    htmlString,
    title
  }: OnGeneratedDraftCtaClickProps) => void
  isFreeUser: boolean
}

const MessageCardMemoized = React.memo(MessageCard)

export const ChatFeed = ({
  chatId,
  messages,
  isLoading,
  loadingSession,
  reload,
  onSourceLinkClick,
  draftTemplateName,
  onGeneratedDraftCtaClick,
  suggestedPrompts,
  suggestedPromptsLoading,
  sendMessage,
  mode,
  isFreeUser
}: ChatFeedProps) => {
  return (
    <ScrollToBottom
      className="h-full grow"
      followButtonClassName="hidden"
      scrollViewClassName="hide-scrollbar"
      debounce={100}
    >
      <ChatFeedContent
        chatId={chatId}
        messages={messages}
        isLoading={isLoading}
        loadingSession={loadingSession}
        reload={reload}
        onSourceLinkClick={onSourceLinkClick}
        draftTemplateName={draftTemplateName}
        onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
        suggestedPrompts={suggestedPrompts}
        suggestedPromptsLoading={suggestedPromptsLoading}
        sendMessage={sendMessage}
        mode={mode}
        isFreeUser={isFreeUser}
      />
    </ScrollToBottom>
  )
}

export const ChatFeedContent = React.memo(function ChatFeedContent({
  chatId,
  messages,
  isLoading,
  loadingSession,
  reload,
  onSourceLinkClick,
  draftTemplateName,
  onGeneratedDraftCtaClick,
  suggestedPrompts,
  suggestedPromptsLoading,
  sendMessage,
  mode,
  isFreeUser
}: ChatFeedProps) {
  const scrollToBottom = useScrollToBottom()

  const lastMessage = messages[messages.length - 1]

  useLayoutEffect(() => {
    scrollToBottom({ behavior: 'smooth' })
  }, [scrollToBottom, lastMessage])

  const { showDelayMessage } = useDelayMessageTimer({ messages, isLoading })

  if (loadingSession) return <Loading />

  const showSuggestedPrompts = mode.mode === 'personalized_qa'

  return (
    <>
      <ScrollToBottomButton />
      <div
        id="ai-chat-global-messages"
        className="hide-scrollbar flex flex-col gap-6 overflow-y-auto"
      >
        <PreambleDefault mode={mode.mode} isFreeUser={isFreeUser} />
        {messages.map((message, index) => {
          const isLastMessage = index === messages.length - 1
          const shouldShowMessageActions =
            message.role === 'assistant' &&
            !message.isPredefined &&
            !message.documents?.draft &&
            !isLoading

          return (
            <Fragment key={message.id}>
              <CopyableProvider>
                <MessageCardMemoized
                  key={message.id}
                  chatId={chatId}
                  message={message}
                  onSourceLinkClick={onSourceLinkClick}
                  onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
                  isLoading={isLoading}
                  isLast={isLastMessage}
                />
                {shouldShowMessageActions && (
                  <Feedback
                    mode={mode}
                    onReload={reload}
                    allowReload={isLastMessage && message.role === 'assistant'}
                    chatId={chatId}
                    message={message}
                    draftTemplateName={draftTemplateName}
                  />
                )}
              </CopyableProvider>
            </Fragment>
          )
        })}
        {showDelayMessage && (
          <div className="flex justify-center">
            <span>⏳ Response generation in progress. Thanks for your patience! ...</span>
          </div>
        )}
        {showSuggestedPrompts && (
          <div className="mt-auto">
            <InChatSuggestedPrompts
              isLoading={isLoading}
              sendMessage={sendMessage}
              suggestedPrompts={suggestedPrompts}
              suggestedPromptsLoading={suggestedPromptsLoading}
            />
          </div>
        )}
      </div>
    </>
  )
})

const PreambleDefault = ({
  mode,
  isFreeUser
}: {
  mode: ModeName
  isFreeUser: boolean
}) => {
  const defaultPreamble = {
    title: 'How can I assist you today?',
    description: isFreeUser
      ? 'Ask me anything about product, marketing, or growth, or try one of the actions below.'
      : "I'm your AI strategic advisor, here to help you leverage our expertise for your work."
  }

  const preambleModeMap: Record<ModeName, { title: string; description: string }> = {
    default: defaultPreamble,
    coaching: defaultPreamble,
    personalized_qa: {
      title: 'Tell me more about how I can help',
      description:
        "I'm here to be your thought partner and give you personalized advice to do your work better and faster. Ask a question, start a brainstorm, whatever you need!"
    },
    document_generation: defaultPreamble,
    suggest_course: {
      title: "Let's find a course just for you",
      description: ''
    }
  }
  return (
    <div className={cn(ASSISTANT_MESSAGE_BUBBLE_CLASSES, 'shrink-0 cursor-default')}>
      <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-rb-gray-400 mb-4">
        <img src={reforgeSymbol} className="h-[12px] w-[12px]" alt="" />
      </div>
      <div className="font-bold text-xl mb-3">{preambleModeMap[mode].title}</div>
      <div className="break-words">{preambleModeMap[mode].description}</div>
    </div>
  )
}
