import React, {
  FormEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'

import Button from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'
import { trackAiChatInputEntered, trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as StopIcon } from 'images/icon--stop.svg'

import { useGlobalChatTracking } from '../GlobalChatTrackingProvider'
import { GlobalChatUIProps } from '../GlobalChatUI'
import { ReactComponent as UpArrow } from './icons/UpArrow'

type UserInputProps = Pick<
  GlobalChatUIProps,
  'chatId' | 'isLoading' | 'sendMessage' | 'setMode' | 'mode' | 'stopGeneration'
> & {
  autofocus: boolean
  adjustableHeight?: boolean
  showCommandMenu: boolean
  setShowDraftMenu: (showDraftMenu: boolean) => void
}

export const UserInput = ({
  showCommandMenu,
  autofocus,
  adjustableHeight = false,
  chatId,
  isLoading,
  sendMessage,
  setMode,
  mode,
  stopGeneration,
  setShowDraftMenu
}: UserInputProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const { pathname } = useLocation()
  const { currentUser } = useCurrentUser()
  const { trackChatActionClicked } = useGlobalChatTracking()

  const [input, setInput] = useState<string>('')
  const [activeTemplate, setActiveTemplate] = useState<string | null>(
    mode.modeOptions?.template_name
  )
  const [activeTemplateLabel, setActiveTemplateLabel] = useState(mode.modeOptions?.label)

  const submitDisabled = !input || isLoading

  useEffect(
    function updateActiveTemplateOnModeChange() {
      setActiveTemplate(mode.modeOptions?.template_name)
      setActiveTemplateLabel(mode.modeOptions?.label)
    },
    [mode.modeOptions]
  )

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value)
  }, [])

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (!input) {
        return
      }

      sendMessage(input, {
        mode: mode.mode,
        modeOptions: mode.modeOptions
      })

      setInput('')

      const textarea = document.getElementById(
        'ai-chat-global-input'
      ) as HTMLTextAreaElement | null
      if (textarea) textarea.setAttribute('style', '')
    },
    [input, sendMessage, mode]
  )

  const adjustHeight = () => {
    const textArea = textAreaRef.current
    if (textArea) {
      textArea.style.height = 'inherit'
      textArea.style.overflowY = 'hidden'
      textArea.style.height = `${textArea.scrollHeight}px`
      const maxHeight = 300

      if (textArea.scrollHeight > 300) {
        textArea.style.overflowY = 'visible'
        textArea.style.height = `${maxHeight}px`
      }
    }
  }

  useEffect(() => {
    if (adjustableHeight) adjustHeight()
  }, [adjustableHeight])

  const handleKeyCommands = (e: KeyboardEvent<HTMLElement>) => {
    // submit the form on enter
    if (e.key === 'Enter' && !e.shiftKey) {
      // prevent newline from being inserted, unless shift is pressed
      e.preventDefault()
      if (!submitDisabled) {
        trackAiChatInputEntered({
          chat_session_id: chatId,
          path: pathname,
          access_policy_kind: currentUser?.accessPolicyKind,
          trial_status: currentUser?.trialStatus,
          is_draft: mode.mode === 'document_generation',
          mode: mode.mode,
          draft_type:
            mode.mode === 'document_generation' ? mode.modeOptions.template_name : null
        })
        const fakeEvent = { preventDefault: () => {} }
        handleSubmit(fakeEvent as FormEvent<HTMLFormElement>)
      }
    }
  }

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (autofocus && textAreaRef.current) {
      textAreaRef.current.focus()
    }
  }, [autofocus])

  const CommandMenuItems = [
    {
      name: 'create_draft',
      label: '🛠️ Create a draft',
      onClick: (buttonText: string) => {
        setShowDraftMenu(true)
        trackChatActionClicked({
          chatId: chatId,
          action: 'draft',
          buttonText
        })
      }
    },
    {
      name: 'get_advice',
      label: '💡 Get strategic advice',
      onClick: (buttonText: string) => {
        setMode({ mode: 'personalized_qa', modeOptions: {} })
        trackChatActionClicked({
          chatId: chatId,
          action: 'chat',
          buttonText
        })
      }
    },
    {
      name: 'suggest_course',
      label: '📚 Suggest a course',
      onClick: (buttonText: string) => {
        setMode({ mode: 'suggest_course', modeOptions: {} })
        sendMessage('Help me find the best course for me', {
          mode: 'suggest_course',
          modeOptions: {}
        })
        trackChatActionClicked({
          chatId: chatId,
          action: 'course_finder',
          buttonText
        })
      }
    }
  ]

  return (
    <div>
      {showCommandMenu && (
        <div className="flex flex-col bg-white p-2 border-b border-rb-gray-100 overflow-hidden">
          {CommandMenuItems.map((item) => (
            <button
              className="text-left cursor-pointer px-4 hover:bg-rb-gray-50 h-12 rounded-lg"
              key={item.name}
              onClick={() => {
                item.onClick(item.label)
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
      {activeTemplate && (
        <div className="flex flex-col divide-y overflow-hidden rounded-t-[20px] border-b border-rb-gray-100">
          <div className="flex items-center py-2.5 gap-4 pl-3 pr-4 text-sm">
            <span className="flex-1 line-clamp-3 py-0.5 font-semibold text-rb-gray-300">
              Drafting a {activeTemplateLabel}
            </span>
          </div>
        </div>
      )}
      <form
        ref={formRef}
        onSubmit={(args) => {
          trackCtaClicked({
            cta_location: 'AI_chat_global_modal',
            cta_type: 'button',
            text: 'ask_question',
            access_policy_kind: currentUser?.accessPolicyKind
          })
          handleSubmit(args)
        }}
        className="relative flex pr-3 pl-5 py-4"
      >
        <div className="w-full flex flex-col">
          <textarea
            id="ai-chat-global-input"
            className="min-h-[22px] outline-none overflow-y-hidden placeholder-[#CBCBCB] resize-none text-[#0C0F0D] text-base w-full"
            ref={textAreaRef}
            rows={1}
            placeholder="Send a message"
            name="message"
            key="message"
            value={input}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={(e) => handleInputChange(e)}
            onKeyDown={(e) => handleKeyCommands(e)}
            required
          />
        </div>
        {submitDisabled && isLoading ? (
          <Button
            className={cn(
              'flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-rb-gray-200 bg-white p-0 transition duration-200 ease-in-out hover:bg-rb-gray-50'
            )}
            data-dd-action-name="AI Sidekick stop inflight response CTA"
            variant="text-only"
            size="x-small"
            tabIndex={0}
            onKeyDown={onEnterKeyPress(stopGeneration)}
            onClick={(e) => {
              e.preventDefault()
              stopGeneration()
            }}
          >
            <StopIcon width={12} className="stroke-rb-gray-400" />
          </Button>
        ) : (
          <Button
            className="p-0"
            dd-action-name="AI sidekick submit button"
            variant="text-only"
            size="x-small"
            type="submit"
            disabled={submitDisabled}
          >
            <div
              className={cn(
                'w-6 h-6 rounded-full flex items-center justify-center shrink-0 bg-[#D5D5D5] transition-colors duration-250',
                { 'bg-[#0C0F0D]': !submitDisabled }
              )}
            >
              <UpArrow />
            </div>
          </Button>
        )}
      </form>
    </div>
  )
}
